import React, { Component } from 'react';
import "./SimplePresentation.css"
import {useTranslation} from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from "react-bootstrap";

// function ShowName() {
//   const { t } = useTranslation();
//
//   return <div className="name">
//     <p>
//       {t("presentation")}
//     </p>
//   </div>
// }

function ShowSocial() {
  const { t } = useTranslation();

  const linkedInLogoPath = "./logo/LI-In-Bug.png";
  const twitterLogoPath = "./logo/Twitter_Logo_Blue.png";
  const githubLogoPath = "./logo/GitHub-Mark-Light-120px-plus.png";
  const oracleJavaSe8CertificationLogoPath = "./logo/oracle-certified-associate-java-se-8-programmer.png";
  const linkedInLink = "https://salim.abdelfettah.dev/api/redirect/LinkedIn";
  const twitterLink = "https://salim.abdelfettah.dev/api/redirect/Twitter";
  const githubLink = "https://salim.abdelfettah.dev/api/redirect/GitHub";
  const oracleJavaSe8CertificationLink = "https://salim.abdelfettah.dev/api/redirect/1Z0-808";

  return <div className="social">
    <Container>
      <Row>
        <Col>
          <a className="logo" href={linkedInLink} target="_blank" rel="noopener noreferrer">
            <img alt={ t("social.linkedin.account") } src={linkedInLogoPath}/>
          </a>
        </Col>
        <Col>
          <a className="logo" href={twitterLink} target="_blank" rel="noopener noreferrer">
            <img alt={ t("social.twitter.account") } src={twitterLogoPath}/>
          </a>
        </Col>
        <Col>
          <a className="logo" href={githubLink} target="_blank" rel="noopener noreferrer">
            <img alt={ t("social.github.account") } src={githubLogoPath}/>
          </a>
        </Col>
        <Col>
          <a className="logo" href={oracleJavaSe8CertificationLink} target="_blank" rel="noopener noreferrer">
            <img alt={ t("certification.oracle.1Z0_808") } src={oracleJavaSe8CertificationLogoPath}/>
          </a>
        </Col>
      </Row>
    </Container>
  </div>
}

export default class SimplePresentation extends Component {
  render() {
    return (
      <div className="simplepresentation">
        {/*<ShowName/>*/}
        <ShowSocial/>
      </div>
    )
  }
}