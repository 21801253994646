import React, { Suspense } from 'react';
import './App.css';
import './i18n';

import SimplePresentation from "./SimplePresentation/SimplePresentation";

function UnderConstruction() {
    return <div className="Website">
        <header className="App-header">
            <SimplePresentation/>
        </header>
    </div>
}

function App() {
  return (
      <Suspense fallback="<div>loading</div>">
          <UnderConstruction/>
      </Suspense>
  );
}

export default App;
