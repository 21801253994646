import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";

let i18nextReactOptions = {
    fallbackLng: "fre",
    debug: false,

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    }
};

i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(i18nextReactOptions);

export default i18n;